.ui.inverted.segment.MobileSegment {
    min-height: 250px;
    padding: 0em 0em;
    background-image: url(/images/fascomp_group.jpg);
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 5em;
}

.MobileContainer .ui.image.mainLogo {
    max-width: 80%;
}

.ui.secondary.inverted.menu.MobileMenu {
  background-color: rgba(0, 0, 0, .75) !important;
    z-index: 10000;
}

.ui.secondary.inverted.menu.MobileMenu.closed {

}

.MobileContainer .mobileChildren {
    background-color: #ffffff
}

.MobileContainer .ui.secondary.pointing.menu .item.partSearch {
    padding: 0;
}
.MobileContainer .partSearch input[type=search] {
    font-size: 1.5em;
    padding: .3em;
    background-color: rgba(0,0,0,0);
    color: #ffffff;
    width: 9em;
}

.MobileContainer .partSearch input[type=text] {
    font-size: 1.5em;
    padding: .3em;
    background-color: rgba(0,0,0,0);
    color: #ffffff;
    width: 9em;
}

.MobileContainer .ui[class*="left fixed"].menu.MobileMenuSide {
    padding-top: 3em;
    -webkit-transition: left 0.5s ease-out;
       -moz-transition: left 0.5s ease-out;
        -ms-transition: left 0.5s ease-out;
         -o-transition: left 0.5s ease-out;
            transition: left 0.5s ease-out;
}

.MobileContainer .ui[class*="left fixed"].menu.MobileMenuSide.closed {
    left: -20rem;
    -webkit-transition: left 0.5s ease-out;
       -moz-transition: left 0.5s ease-out;
        -ms-transition: left 0.5s ease-out;
         -o-transition: left 0.5s ease-out;
            transition: left 0.5s ease-out;
}


.MobileContainer .mobileChildren {
    padding-top: 4em;
}

.pagehome .MobileContainer .mobileChildren {
    padding-top: 0;
}


.MobileContainer .menu .ui.search .ui.icon i {
    color: #fff;
}

.MobileContainer .ui.search .ui.icon i {
    vertical-align: middle;
    font-size: 1.5em;
}

.MobileContainer .ui.search>.results {
    margin-left: -60px;
}
.categoryLanding .categoryImage {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.desktopChildren .categoryLanding .categoryImage {
  width: 40%;
}

.categoryLanding .ui.card {
  width: 100%;
}


.categoryLanding .ui.card > img {
  display: none;
}

.DesktopContainer .desktopChildren .desktopMain.fullWidth .ui.container {
   width: 95% !important;

}

.ui.modal .actions {
  min-height: 60px;
}

.userDetailsModal .field .ui.input {
  width: 100%;
}

.userDetailsModal .field .ui.input>input {
  max-width: 250px;
}

.userDetailsModal .companyInput .field .ui.input>input {
  max-width: 450px;
}

.userDetailsModal .emailInput .field .ui.input>input {
  max-width: 350px;
}

.userDetailsModal .modalbutton.modalDelete {
  float: left;
}

.pageAdminUsers button.addUser {
  float: left;
}

.quoteDetailsModal .quantityInput .ui.input>input,
.quoteDetailsModal .priceInput .ui.input>input,
.quoteDetailsModal .platingPriceInput .ui.input>input,
.quoteDetailsModal .deliveryInput .ui.input>input {
  font-size: 8pt;
}

.quoteDetailsModal .deliveryInput .ui.input>input {
  margin-left: 10px;
}

.quoteDetailsModal .quantityInput input,
.quoteDetailsModal .priceInput input,
.quoteDetailsModal .platingPriceInput input,
.quoteDetailsModal .deliveryInput input {
  width: 6em;
}

.quoteDetailsModal .salesRepDropdown .ui.selection.dropdown {
  width: 300px;
}

.quoteDetailsModal textarea.adminCommentsInput {
  width: 100%;
  height: 60px;
}

.quoteDetailsModal .priceLabels {
  line-height: 22pt;
}



@media only screen and (min-width: 1920px) {
  .ui.modal {
      width: 1200px;
      margin: 0;
  }
}